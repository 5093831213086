import React, { memo, useEffect, useRef } from 'react'
import gsap from 'gsap'
import styles from './IntroLoader.module.scss'
import SanityImage from 'components/SanityImage/SanityImage'

import useStore from 'store'
import { useState } from 'react'

const MOUSEOVER_DISTANCE = 50
const LOAD_SPEED = 4

function IntroLoader() {
  const innerRef = useRef()
  const loaderData = useStore(state => state.loaderData)
  const portfolioLoadPercent = useStore(state => state.portfolioLoadPercent)
  const setLoaderAnimationComplete = useStore(state => state.setLoaderAnimationComplete)
  const targetValues = useRef({ x: 0, y: 0 })
  const imageRefs = useRef({})
  const loadTextRef = useRef()
  const activeIndex = useRef(0)
  const zIndexes = useRef(1)
  const pauseTimeout = useRef(null)
  const isLoaded = useRef(false)
  const loadingPercentRendered = isNaN(Math.round(portfolioLoadPercent * 100))
    ? 0
    : Math.round(portfolioLoadPercent * 100)
  const allowMouseTrail = useRef(true)
  const [intervalComplete, setIntervalComplete] = useState(true)
  const [percentAnimationComplete, setPercentAnimationComplete] = useState(false)
  const intervalRef = useRef()
  const loadedPercentRef = useRef(0)
  const tweenRef = useRef(null)
  const [fadeOutAnim, setFadeOutAnim] = useState(false)

  const positionImage = (x, y) => {
    if (
      Math.abs(targetValues.current.x - x) > MOUSEOVER_DISTANCE ||
      Math.abs(targetValues.current.y - y) > MOUSEOVER_DISTANCE
    ) {
      targetValues.current.x = x
      targetValues.current.y = y
      zIndexes.current = zIndexes.current + 1

      const activeImage = imageRefs.current[activeIndex.current]

      if (!activeImage) return

      gsap.killTweensOf(Object.values(imageRefs.current))

      gsap.set(activeImage, {
        autoAlpha: 1,
        zIndex: zIndexes.current,
        y: targetValues.current.y - activeImage.offsetHeight * 0.5,
        x: targetValues.current.x - activeImage.offsetWidth * 0.5,
      })

      gsap.set(innerRef.current, {
        zIndex: zIndexes.current + 10,
      })

      if (activeIndex.current === Object.values(imageRefs.current).length - 1) {
        activeIndex.current = 0
      } else {
        activeIndex.current = activeIndex.current + 1
      }
    }
  }

  const hideImages = () => {
    const order = []

    let inc = activeIndex.current === 0 ? Object.values(imageRefs.current).length - 1 : activeIndex.current - 1
    for (let index = 0; index < Object.values(imageRefs.current).length; index++) {
      order.push(inc)
      if (inc === 0) {
        inc = Object.values(imageRefs.current).length - 1
      } else {
        inc = inc - 1
      }
    }

    order.reverse()

    order.forEach((index, i) => {
      const image = imageRefs.current[index]
      gsap.to(image, {
        autoAlpha: 0,
        delay: i * 0.05,
        duration: 0.00001,
      })
    })
  }

  useEffect(() => {
    const handleMouseOver = e => {
      if (!allowMouseTrail.current) return
      if (!isLoaded.current) {
        const xCoord = e.changedTouches?.length ? e.changedTouches[0]?.clientX : e.clientX
        const yCoord = e.changedTouches?.length ? e.changedTouches[0]?.clientY : e.clientY

        positionImage(xCoord, yCoord)

        if (pauseTimeout.current) {
          clearTimeout(pauseTimeout.current)
          pauseTimeout.current = null
        }

        pauseTimeout.current = setTimeout(() => {
          hideImages()
        }, 10)
      }
    }

    document.addEventListener('mousemove', handleMouseOver)

    return () => {
      document.removeEventListener('mousemove', handleMouseOver)
      if (pauseTimeout.current) {
        clearTimeout(pauseTimeout.current)
        pauseTimeout.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (!loadTextRef.current) return
    if (tweenRef?.current?.isActive()) return

    tweenRef.current = gsap.to(loadedPercentRef, {
      current: 99,
      duration: LOAD_SPEED,
      ease: 'Power4.easeInOut',
      onUpdate: () => {
        const value = Math.round(loadedPercentRef.current)
        loadTextRef.current.innerHTML = value
      },
      onComplete: () => {
        setPercentAnimationComplete(true)
      },
    })
  }, [loadingPercentRendered, setPercentAnimationComplete])

  useEffect(() => {
    if (!percentAnimationComplete || loadingPercentRendered < 100) return
    setFadeOutAnim(true)
  }, [percentAnimationComplete, loadingPercentRendered])

  useEffect(() => {
    if (!fadeOutAnim) return

    allowMouseTrail.current = false

    gsap.to(loadTextRef.current, {
      autoAlpha: 0,
      duration: 0.8,
      onComplete: () => {
        setLoaderAnimationComplete(true)
      },
    })
  }, [fadeOutAnim])

  return (
    <div className={styles.Loader}>
      {loaderData?.images?.length && (
        <>
          {loaderData?.images.map((image, i) => (
            <div
              key={i}
              ref={ref => (imageRefs.current[i] = ref)}
              className={styles.imageContainer}
            >
              <SanityImage
                className={styles.image}
                image={image}
                width={200}
                unoptimized
              />
            </div>
          ))}
        </>
      )}
      <div
        className={styles.inner}
        ref={innerRef}
      >
        <div className={styles.texts}>
          <span
            ref={loadTextRef}
            className={styles.loadedPercent}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(IntroLoader)
